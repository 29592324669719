import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import vuetify from './plugins/vuetify'

// import VueRouter from 'vue-router'
import { loadFonts } from './plugins/webfontloader'

import { content } from './utils/content.js'

loadFonts()

const app = createApp(App)

app.config.globalProperties.$ttcContent = content

app.use(vuetify)
app.use(router)

// set up log method everywhere
app.config.globalProperties.$log = console.log

app.mount('#app')
