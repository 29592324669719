import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/MainView.vue'),
    meta: {
      title: 'TTC - Home',
    },
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'TTC - About us',
    },
  },
  {
    path: '/consulting-services',
    name: 'Consulting Services',
    component: () => import('../views/ConsultingServicesView.vue'),
    meta: {
      title: 'TTC - Consulting',
    },
  },
  {
    path: '/terms',
    name: 'Terms & Conditions',
    component: () => import('../views/TermsView.vue'),
    meta: {
      title: 'TTC - Terms & Conditions',
    },
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyView.vue'),
    meta: {
      title: 'TTC - Privacy Policy',
    },
  },
  {
    path: '/pay',
    name: 'Payments',
    component: () => import('../views/PaymentView.vue'),
    meta: {
      title: 'TTC - Payment information',
    },
  },
  {
    path: '/training',
    name: 'Training',
    component: () => import('../views/TrainingView.vue'),
    meta: {
      title: 'TTC - Training',
    },
  },
  {
    path: '/code',
    name: 'Coding',
    component: () => import('../views/CodeMirrorView.vue'),
    meta: {
      title: 'TTC - Coding Examples',
    },
  },
]

// router stuff
const router = createRouter({
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach((to, _from, next) => {
  document.title = `${to.meta.title}`
  next()
})

export default router
