<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer v-model="drawer" permanent>
        <v-list density="compact" nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.path"
            :title="item.title"
            :value="item.title"
            :prepend-icon="item.icon"
            :color="item.iconColor"
            link
          ></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main style="height: 350px"> </v-main>
    </v-layout>
  </v-card>
</template>
<script>
const colors = ['#1972a4', '#5e9cbf', '#a3c7db', '#73f7dd', '#51ad9b', '#2e6358']

export default {
  name: 'NavigationCard',
  data() {
    return {
      drawer: true,
      items: [
        { title: 'Home', iconColor: colors[0], icon: 'mdi-home-assistant', path: '/' },
        { title: 'About', iconColor: colors[1], icon: 'mdi-axis-arrow-info', path: '/about' },
        // {
        //   title: 'Training',
        //   iconColor: colors[2],
        //   icon: 'mdi-account-star-outline',
        //   path: '/training',
        // },
        {
          title: 'Consulting Services',
          iconColor: colors[3],
          icon: 'mdi-head-snowflake-outline',
          path: '/consulting-services',
        },
        // {
        //   title: 'Payments',
        //   iconColor: colors[4],
        //   icon: 'mdi-credit-card-outline',
        //   path: '/pay',
        // },
        {
          title: 'Terms & Conditions',
          iconColor: colors[5],
          icon: 'mdi-file-document-outline',
          path: '/terms',
        },
        {
          title: 'Privacy',
          iconColor: colors[0],
          icon: 'mdi-security',
          path: '/privacy',
        },
      ],
      rail: false,
    }
  },

  methods() {},
}
</script>
<style></style>
