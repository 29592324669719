export const content = {
  home: {
    title: 'Optimizing Your Marketing and Technoly Success',
    intro:
      'Welcome to TTC. We offer consulting and marketing support for website with a focus on marine engineering services. Our team of experts specializes in providing comprehensive consulting and marketing support services to marine engineering companies like yours. Our aim is to help you achieve business growth and success in the marine engineering industry.',
  },
  consulting_services: {
    title: 'Consulting Services',
    services: [
      {
        title: 'Technical Consulting',
        description:
          'Our team of experts can provide you with technical guidance and support to help you achieve optimal marine engineering designs.',
        price: 'EUR 2500,- (dayrate)',
      },
      {
        title: 'Project Management Consulting',
        description:
          'We have extensive experience in project management and can help you manage your marine engineering projects more effectively.',
        price: 'EUR 1500,- (dayrate)',
      },
      {
        title: 'Quality Management Consulting',
        description:
          'Our experts can help you improve your quality management systems, ensuring that your marine engineering services meet international standards.',
        price: 'EUR 2000,- (dayrate)',
      },
    ],
  },
  marketing_support_services: {
    title: 'Marketing Support Services',
    services: [
      {
        title: 'Branding and Identity',
        description:
          'We can help you develop a unique brand identity that resonates with your target market.',
        price: 'EUR 1500,- (dayrate)',
      },
      {
        title: 'Digital Marketing',
        description:
          'Our team of experts can help you leverage digital marketing channels to increase your online visibility and reach your target audience.',
        price: 'EUR 1100,- (dayrate)',
      },
      {
        title: 'Sales and Business Development',
        description:
          'We can help you identify new sales opportunities and develop effective sales strategies to grow your marine engineering services.',
        price: 'EUR 3500,- (retainer)',
      },
    ],
  },

  contact: {
    title: 'Contact Us',
    address: '',
    email: 'info@marineengineeringconsulting.com',
  },
}
